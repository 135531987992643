.ContactContainer{
  position: relative;
  width: calc(100% - 48px);
  padding: 96px 24px;
}

.ContactImg{
  position: relative;
  display: inline-block;
  width: 20%;
  margin: 0 15%;
  height: 60%;
  max-width: 600px;
  vertical-align: middle;
  object-fit: cover;
}

.ContactBody{
  position: relative;
  display: inline-block;
  width: 40%;
  margin: 0 5%;
  height: 60%;
  max-width: 600px;
  vertical-align: middle;
}

.ContactLabel{
  font-size: 12px;
  font-weight: 200;
  color: #222;
  padding: 4px 0px;
  display: block;
}

.ContactInput{
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  padding: 8px 16px;
  margin: 4px;
  font-size: 16px;
  font-weight: 300;
  color: #222;
  display: block;
  width: calc(100% - 40px);
  font-family: 'Nunito Sans', sans-serif;
  font-display: swap;
}

.ContactButton{
  padding: 12px 36px;
  margin: 12px 4px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  color: white;
  background: #ccc;
  border:0;
  border-radius: 8px;
  transition: all .2s;
  box-shadow: 1px 5px 10px -1px #00000066;
}

.ContactButton:hover{
  transform: scale(1.02);
  box-shadow: 1px 8px 15px -2px #00000066;

}



@media only screen and (max-width: 800px){
  .ContactImg{
    width: 92%;
    padding: 4%;
    margin: 0;
  }
  .ContactBody{
    width: 92%;
    padding: 4%;
    margin: 0;
  }
}
