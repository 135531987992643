.LocationContainer{
  position: relative;
  width: calc(100% - 48px);
  /* min-height: calc(100vh - 48px); */
  padding: 24px;
}

.LocationMap{
  position: relative;
  display: inline-block;
  width: 40%;
  margin: 0 5%;
  height: 400px;
  max-width: 600px;
  vertical-align: middle;
}

.LocationCardsContainer{
  position: relative;
  display: inline-block;
  width: 40%;
  margin: 0 5%;
  max-width: 400px;
  vertical-align: middle;
  margin: 24px;
  overflow: visible;
}

.LocationCardsContainer::before{
  content:'';
  position: absolute;
  top: 0;
  left: -2px;
  width: 16%;
  height: 100%;
  z-index: 9;
  background-image: linear-gradient(to right, #fff , rgba(255,255,255,0.001));
}

.LocationCardsContainer::after{
  content:'';
  position: absolute;
  top: 0;
  right: -2px;
  width: 16%;
  height: 100%;
  background-image: linear-gradient(to right, rgba(255,255,255,0.001) , #fff);
}

.LocationCardWrapper{
  background: #fff;
  position: relative;
}

.LocationCard{
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 1px 5px 10px -2px #00000044;
  width: calc(100% - 40px);
  margin: 16px 12px;
  padding: 8px;
  text-align: center;
}

.LocationCardLogo{
  width: 32%;
  margin: auto;
  padding: 12px;
}

.LocationCardHeader{
  font-size: 18px;
  font-weight: 900;
  color: #222;
  margin: 0;
}

.LocationCardButton{
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 3px;
  background:#ccc;
  color: #fff;
  padding: 8px 24px;
  border:0;
  border-radius: 12px;
  box-shadow: 1px 5px 10px -3px #00000088;
  transition: all .2s;
  margin: 12px auto;
  text-transform: uppercase;
}

.LocationCardButton:hover{
  transform: scale(1.02);
  box-shadow: 1px 10px 12px -3px #00000088;
  cursor: pointer;
}

.LocationCardAddress{
  font-size: 14px;
  font-weight: 700;
  padding: 8px;
  line-height: 1.2;
}

.LocationCardHours{
  font-size: 12px;
  font-weight: 600;
  color: #222;
  padding: 12px;
  line-height: 1.6;
}

.LocationCardsNext{
  position: absolute;
  top: 50%;
  right: -42px;
  width: 42px;
  transition: all .2s;
  opacity: .4;
  cursor: pointer;
  z-index: 9;
  background: transparent;
}

.LocationCardsNext:hover{
  opacity: .8;
  transform: scale(1.1);
}

.LocationCardsPrev{
  position: absolute;
  top: 50%;
  left: -42px;
  width: 42px;
  transition: all .2s;
  opacity: .4;
  cursor: pointer;
  z-index: 9;
  background: transparent;
}

.LocationCardsPrev:hover{
  opacity: .8;
  transform: scale(1.1);
}

.LocationList{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.LocationListHours{
  font-size: 12px;
  font-weight: 600;
  color: #222;
  padding: 4px;
  line-height: 1.6;
}

.LocationListElement{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 8px 16px;
  margin: 4px;
  border-radius: 8px;
  border: 1px solid #e1e1e1;
  width: 100%;
  vertical-align: top;
}

.LocationSelected{
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  padding: 8px 16px;
  margin: 4px;
  border-radius: 8px;
  border: 1px solid #e1e1e1;
}

.LocationButtons{
  width: 30%;
  margin: 16px 0;
}

.LocationSelectedHeader{
  font-size: 24px;
  font-weight: 900;
  color: #222;
  margin: 0;
}

.LocationSelectedLogo {
  width: 64%;
  box-sizing: border-box;
  padding: 16px;
  vertical-align: middle;
}

.LocationSelectedText{
  /* width: 33%; */
  box-sizing: border-box;
  padding: 8px 32px;
  text-align: center;
  vertical-align: middle;
}

.LocationListButton{
  width: 100%;
  padding: 12px 32px;
  box-sizing: border-box;
  color: white;
  border: 0;
  border-radius: 8px;
  margin: 2px;
  font-size: 16px;
  cursor: pointer;
}

.LocationDirections{
  font-weight: 700;
  text-decoration: none;
  margin: 2px;
}

.LocationPhone{
  font-weight: 700;
  text-decoration: none;
  margin: 2px;
}

@media only screen and (max-width: 800px){
  .LocationMap{
    width: 100%;
    height: 300px;
    margin: 12px 0px;
  }
  .LocationCardsContainer{
    width: 100%;
    margin: 0px;
  }
  .LocationCardsPrev{
    left: -24px;
  }
  .LocationCardsNext{
    right: -24px;
  }
  .LocationList{
    flex-direction: column;
  }
  .LocationSelected{
    flex-direction: column;
  }
  .LocationSelectedLogo {
    width: 48%;
  }
  
  .LocationSelectedText{
    width: 100%;
  }

  .LocationButtons{
    width: 100%;
  }
}

.restMapContainer{
  position: relative;
  display: inline-block;
  width: 100%;
  height: 400px;
  background: #ccc;
  border-radius: 16px;
  overflow: hidden;
}

.restMapAddressInput{
  position: absolute;
  top: 10px;
  left: 8px;
  width: calc(100% - 112px);
  padding: 12px 16px;
  font-size: 14px;
  color: #333;
  border: 0;
  border-radius: 4px;
  background: #fff;
  box-shadow: 1px 5px 20px -1px #00000033;
  z-index: 9;
}

.restMapAutocompleteContainer{
  position: absolute;
  top: 56px;
  left: 8px;
  width: calc(100% - 48px);
  padding: 12px 16px;
  font-size: 14px;
  color: #333;
  border: 0;
  border-radius: 4px;
  background: #fff;
  box-shadow: 1px 10px 10px -1px #00000033;
  z-index: 9;
}

.restMapAutocompleteContainer:empty{
  height: 0;
  padding: 0;
}

.restMapImg{
  position: relative;
  min-width: 100%;
  object-fit: cover;
}

.restMapAddressSubmit{
  position: absolute;
  top: 17px;
  right: 16px;
  height: 24px;
  width: 24px;
  padding: 4px;
  background: #4284f4;
  border: 0;
  border-radius: 4px;
  z-index: 901;
  cursor: pointer;
}