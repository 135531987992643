.slick-initialized .slick-track {
  display: flex;
  align-items: center;
}

.GalleryContainer{
  position: relative;
  width: calc(100% - 48px);
  padding: 24px;
}

.GalleryImg{
  /* height: 200px; */
  width: 90%;
  object-fit: cover;
  vertical-align: middle;
  cursor: pointer;
}

.GalleryContainer div {
  vertical-align: middle;
}

.GalleryCardContainer{
  position: relative;
  display: inline-block;
  width: 80%;
  margin: 64px 10%;
  vertical-align: middle;
  overflow: visible;
}

.GalleryCardContainer::before{
  content:'';
  position: absolute;
  top: 0;
  left: -2px;
  width: 16%;
  height: 100%;
  z-index: 9;
  background-image: linear-gradient(to right, #fff , rgba(255,255,255,0.001));
}

.GalleryCardContainer::after{
  content:'';
  position: absolute;
  top: 0;
  right: -2px;
  width: 16%;
  height: 100%;
  background-image: linear-gradient(to right, rgba(255,255,255,0.001) , #fff);
}

.ReviewWrapper{
  background: #fff;
  position: relative;
}

.ReviewCard{
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 1px 5px 10px -2px #00000044;
  margin: 16px 12px;
  padding: 24px;
  text-align: center;
}

.GalleryCardsNext{
  position: absolute;
  top: 50%;
  right: -42px;
  width: 42px;
  transition: all .2s;
  opacity: .4;
  cursor: pointer;
}

.GalleryCardsNext:hover{
  opacity: .8;
  transform: scale(1.1);
}

.GalleryCardsPrev{
  position: absolute;
  top: 50%;
  left: -42px;
  width: 42px;
  transition: all .2s;
  opacity: .4;
  cursor: pointer;
}

.GalleryCardsPrev:hover{
  opacity: .8;
  transform: scale(1.1);
}

@media only screen and (max-width: 800px){
  .GalleryCardContainer{
    width: 100%;
    margin: 64px 0px;
  }
  .GalleryCardsNext{
    position: absolute;
    top: 50%;
    right: -16px;
    width: 32px;
    z-index: 11;
    transition: all .2s;
    opacity: .4;
    cursor: pointer;
  }
  
  .GalleryCardsPrev{
    position: absolute;
    top: 50%;
    left: -16px;
    width: 32px;
    z-index: 11;
    transition: all .2s;
    opacity: .4;
    cursor: pointer;
  }
}