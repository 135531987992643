.ReviewsContainer{
  position: relative;
  width: calc(100% - 48px);
  padding: 24px;
}

.ReviewsCardContainer{
  position: relative;
  display: inline-block;
  width: 80%;
  margin: 64px 10%;
  vertical-align: middle;
  overflow: visible;
}

.ReviewsCardContainer::before{
  content:'';
  position: absolute;
  top: 0;
  left: -2px;
  width: 16%;
  height: 100%;
  z-index: 9;
  background-image: linear-gradient(to right, #fff , rgba(255,255,255,0.001));
}

.ReviewsCardContainer::after{
  content:'';
  position: absolute;
  top: 0;
  right: -2px;
  width: 16%;
  height: 100%;
  background-image: linear-gradient(to right, rgba(255,255,255,0.001) , #fff);
}

.ReviewWrapper{
  background: #fff;
  position: relative;
}

.ReviewCard{
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 1px 5px 10px -2px #00000044;
  margin: 16px 12px;
  padding: 24px 8px;
  text-align: center;
}

.ReviewCardLogo{
  width: 48%;
  margin: auto;
  padding: 12px;
}

.ReviewCardHeader{
  font-size: 18px;
  font-weight: 900;
  color: #222;
}

.ReviewCardButton{
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1px;
  color: #fff;
  padding: 8px 24px;
  border:0;
  border-radius: 12px;
  box-shadow: 1px 5px 10px -3px #00000088;
  transition: all .2s;
  margin: 4px auto;
  text-transform: uppercase;
}

.ReviewCardButton:hover{
  transform: scale(1.02);
  box-shadow: 1px 10px 12px -3px #00000088;
  cursor: pointer;
}

.ReviewsCardHours{
  font-size: 14px;
  font-weight: 200;
  color: #222;
  padding: 12px;
}

.ReviewsCardsNext{
  position: absolute;
  top: 50%;
  right: -42px;
  width: 42px;
  transition: all .2s;
  opacity: .4;
  cursor: pointer;
}

.ReviewsCardsNext:hover{
  opacity: .8;
  transform: scale(1.1);
}

.ReviewsCardsPrev{
  position: absolute;
  top: 50%;
  left: -42px;
  width: 42px;
  transition: all .2s;
  opacity: .4;
  cursor: pointer;
}

.ReviewsCardsPrev:hover{
  opacity: .8;
  transform: scale(1.1);
}

.ReviewCardBody{
  font-size: 12px;
  font-weight: 600;
  color: #aaa;
  padding: 8px 0px 24px 0;
  font-weight: 500;
  color: #888;
  position: relative;
  position: relative;
  height: 12em;
  overflow-y: auto;
}

@media only screen and (max-width: 800px){
  .ReviewsCardContainer{
    width: 100%;
    padding: 0;
    margin: 0;
  }
  .ReviewCardButton{
    padding: 8px 0;
    width: 100%;
  }
  /* .ReviewCardBody{
    font-size: 12px;
    font-weight: 500;
    color: #666;
    padding: 8px 0px;
  } */
  .ReviewsCardsNext{
    position: absolute;
    top: 50%;
    right: -16px;
    width: 32px;
    z-index: 11;
    transition: all .2s;
    opacity: .4;
    cursor: pointer;
  }
  
  .ReviewsCardsPrev{
    position: absolute;
    top: 50%;
    left: -16px;
    width: 32px;
    z-index: 11;
    transition: all .2s;
    opacity: .4;
    cursor: pointer;
  }
}