.SplashContainer{
  position: relative;
  width: calc(100% - 48px);
  height: calc(100vh - 96px);
  padding: 48px 24px 0px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  flex-direction: column;
}

.SplashBackground{
  position: fixed;
  object-fit: cover;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}

.SplashHeader{
  font-size: 96px;
  font-weight: 900;
  margin: 24px 0px;
  text-shadow: 1px 1px 20px #00000066;
  text-transform: uppercase;
  z-index: 1;
}

.SplashLogo{
  /* padding: 12px; */
  width: 32%;
  z-index: 1;
  min-height: 1px;
}

.SplashSubHeader{
  font-size: 24px;
  font-weight: 900;
  text-shadow: 1px 1px 10px #00000066;
  letter-spacing: 2px;
  text-transform: uppercase;
  z-index: 1;
}

.SplashButton{
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 3px;
  background:#ccc;
  color: #fff;
  padding: 12px 48px;
  margin: 24px;
  border:0;
  border-radius: 12px;
  box-shadow: 1px 5px 10px 1px #00000088;
  transition: all .2s;
}

.SplashButton:hover{
  transform: scale(1.02);
  box-shadow: 1px 10px 12px 1px #00000088;
  cursor: pointer;
}

@media only screen and (max-width: 800px){
  .SplashHeader{
    font-size: 56px;
    line-height: 1;
  }
  .SplashSubHeader{
    font-size: 18px;
  }
  .SplashLogo{
    width: 84%;
  }
  .SplashButton{
    width: 100%;
    padding: 12px 0;
    font-size: 18px;
    margin:0;
  }
}

.SplashDownArrow{
  width: 64px;
  margin: 24px;
  animation: pulse 1s infinite;
}

.SplashDownArrow:hover {
  transform: scale(1.1);
  cursor: pointer;
}

@keyframes pulse{
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-8px);
  }
  100% {
    transform: translateY(0px);
  }
}

.SplashContainerHorizontal{
  position: relative;
  width: calc(100% - 48px);
  height: calc(100vh - 96px);
  padding: 48px 24px 0px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.SplashSubHeaderHorizontal{
  font-size: 48px;
  line-height: 1.2;
  font-weight: 900;
  text-shadow: 1px 1px 10px #00000066;
  z-index: 1;
  width: 60%;
  text-align: left;
  vertical-align: middle;
}

.SplashSubHeaderHorizontal button {
  display: inline-block;
  margin: 2%;
  font-size: 14px;
  padding: 12px 24px;
}

.SplashLogoHorizontal{
  width: 30%;
  margin: 0 32px;
}

@media only screen and (max-width: 800px){
  .SplashContainerHorizontal{
    flex-direction: column-reverse;
    flex-wrap: nowrap;
  }
  .SplashSubHeaderHorizontal{
    font-size: 24px;
    font-weight: 900;
    text-shadow: 1px 1px 10px #00000066;
    z-index: 1;
    display: inline-block;
    width: 40%;
    text-align: center;
    vertical-align: middle;
    width: 92%;
  }
  .SplashLogoHorizontal{
    width: 84%;
  }
  .SplashSubHeaderHorizontal button {
    display: inline-block;
    width: 92%;
    margin: 8px 4%;
    font-size: 14px;
    padding: 12px 0;
  }
}