.AnnouncementBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0,.5);
  z-index: 998;
}

.AnnouncementContainer{
  position: fixed;
  width: calc(100% - 32px);
  min-height: 100px;
  max-height: 90%;
  max-width: 600px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 2px 8px 48px -16px rgba(0,0,0,.4);
  z-index: 11;
  animation: slide-up .5s forwards ease;
  -webkit-animation: slide-up .5s forwards ease;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
}

.AnnouncementClose{
  position: absolute;
  top: 12px;
  right: 12px;
  height: 20px;
  opacity: .8;
  cursor: pointer;
  z-index: 12;
  border-radius: 50%;
  background: #fff;
  padding: 4px;
}

.AnnouncementHeader{
  font-size: 24px;
  font-weight: 900;
  color: #222;
  margin: 0px 24px;
}

.AnnouncementImg{
  width: 32%;
  margin: 12px auto;
}

.AnnouncementBody{
  font-size: 18px;
  font-weight: 400;
  color: #222;
  /* margin: 8px 16px; */
}

@keyframes drop-in {
  0% {
    transform: translateY(-100%);
    opacity: 0;
    margin-top: -2.5vh;
  }
  30% {
    opacity: 0;
    transform: translateY(-20%);
    margin-top: 0vh;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
    margin-top: 2.5vh;
  }
}