.HeaderContainer{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background: rgba(0,0,0,.5);
  color: #fff;
  z-index: 10;
}

.HeaderButton{
  position: relative;
  display: inline-block;
  border: 0;
  border-radius: 8px;
  padding: 8px 12px;
  margin-right: 8px;
  color: white;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: .5px;
  text-transform: uppercase;
  transition: all .2s;
  cursor: pointer;
}

.HeaderButton:hover{
  transform: scale(1.01);
  box-shadow: 1px 5px 10px -1px #00000066;
}

.HeaderImg{
  height: 48px;
  padding: 6px;
  transition: all .2s;
  margin-right: 16px;
}

.HeaderImg:hover{
  height: 56px;
  padding: 2px;
  cursor: pointer;
}

.HeaderSocialImg{
  height: 24px;
  padding: 18px 8px;
  transition: all .2s;
  opacity: .5;
}

.HeaderSocialImg:hover{
  opacity: 1;
  cursor: pointer;
}

.HeaderLinks{
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  text-align: right;
}

.HeaderLinksMobile{
  position: fixed;
  top: 60px;
  right: 0px;
  width: 100%;
  text-align: right;
  background: rgba(0,0,0,.7);
  padding-bottom: 8px;
}

.HeaderLink{
  position: relative;
  display: inline-block;
  padding: 18px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: .5px;
  text-transform: uppercase;
  transition: all .2s;
  cursor: pointer;
}

.HeaderLink:hover{
  font-weight: 800;
  cursor: pointer;
}

.HeaderLinkMobile{
  position: relative;
  display: block;
  padding: 8px;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: .5px;
  text-transform: uppercase;
  transition: all .2s;
  cursor: pointer;
}

.HeaderMenu{
  position: absolute;
  top: 0;
  right: 0;
  height: 36px;
  padding: 8px;
}