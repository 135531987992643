@import url(https://fonts.googleapis.com/css?family=Nunito+Sans:100,200,300,400,500,600,700,800,900);
.SplashContainer{
  position: relative;
  width: calc(100% - 48px);
  height: calc(100vh - 96px);
  padding: 48px 24px 0px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  flex-direction: column;
}

.SplashBackground{
  position: fixed;
  object-fit: cover;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}

.SplashHeader{
  font-size: 96px;
  font-weight: 900;
  margin: 24px 0px;
  text-shadow: 1px 1px 20px #00000066;
  text-transform: uppercase;
  z-index: 1;
}

.SplashLogo{
  /* padding: 12px; */
  width: 32%;
  z-index: 1;
  min-height: 1px;
}

.SplashSubHeader{
  font-size: 24px;
  font-weight: 900;
  text-shadow: 1px 1px 10px #00000066;
  letter-spacing: 2px;
  text-transform: uppercase;
  z-index: 1;
}

.SplashButton{
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 3px;
  background:#ccc;
  color: #fff;
  padding: 12px 48px;
  margin: 24px;
  border:0;
  border-radius: 12px;
  box-shadow: 1px 5px 10px 1px #00000088;
  -webkit-transition: all .2s;
  transition: all .2s;
}

.SplashButton:hover{
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
  box-shadow: 1px 10px 12px 1px #00000088;
  cursor: pointer;
}

@media only screen and (max-width: 800px){
  .SplashHeader{
    font-size: 56px;
    line-height: 1;
  }
  .SplashSubHeader{
    font-size: 18px;
  }
  .SplashLogo{
    width: 84%;
  }
  .SplashButton{
    width: 100%;
    padding: 12px 0;
    font-size: 18px;
    margin:0;
  }
}

.SplashDownArrow{
  width: 64px;
  margin: 24px;
  -webkit-animation: pulse 1s infinite;
          animation: pulse 1s infinite;
}

.SplashDownArrow:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  cursor: pointer;
}

@-webkit-keyframes pulse{
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@keyframes pulse{
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

.SplashContainerHorizontal{
  position: relative;
  width: calc(100% - 48px);
  height: calc(100vh - 96px);
  padding: 48px 24px 0px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.SplashSubHeaderHorizontal{
  font-size: 48px;
  line-height: 1.2;
  font-weight: 900;
  text-shadow: 1px 1px 10px #00000066;
  z-index: 1;
  width: 60%;
  text-align: left;
  vertical-align: middle;
}

.SplashSubHeaderHorizontal button {
  display: inline-block;
  margin: 2%;
  font-size: 14px;
  padding: 12px 24px;
}

.SplashLogoHorizontal{
  width: 30%;
  margin: 0 32px;
}

@media only screen and (max-width: 800px){
  .SplashContainerHorizontal{
    flex-direction: column-reverse;
    flex-wrap: nowrap;
  }
  .SplashSubHeaderHorizontal{
    font-size: 24px;
    font-weight: 900;
    text-shadow: 1px 1px 10px #00000066;
    z-index: 1;
    display: inline-block;
    width: 40%;
    text-align: center;
    vertical-align: middle;
    width: 92%;
  }
  .SplashLogoHorizontal{
    width: 84%;
  }
  .SplashSubHeaderHorizontal button {
    display: inline-block;
    width: 92%;
    margin: 8px 4%;
    font-size: 14px;
    padding: 12px 0;
  }
}
.SectionHeaderContainer{
  position: relative;
  display: inline-block;
  padding: 8px 32px;
  margin: 24px;
  color: #fff;
  text-transform: uppercase;
  font-size: 48px;
  font-weight: 900;
  letter-spacing: 2px;
  text-shadow: 1px 4px 8px #00000066;
  vertical-align: middle;
}

.SectionHeaderMenuLink{
  position: relative;
  display: inline-block;
  padding: 32px;
  font-size: 24px;
  font-weight: 300;
  vertical-align: middle;
  text-align: center;
}

.SectionHeaderMenuImg{
  position: relative;
  display: inline-block;
  padding: 8px;
  width: 32px;
  vertical-align: middle;
}

@media only screen and (max-width: 800px){
  .SectionHeaderContainer{
    font-size: 32px;
    margin: 12px;
    line-height: 1;
  }
}
.LocationContainer{
  position: relative;
  width: calc(100% - 48px);
  /* min-height: calc(100vh - 48px); */
  padding: 24px;
}

.LocationMap{
  position: relative;
  display: inline-block;
  width: 40%;
  margin: 0 5%;
  height: 400px;
  max-width: 600px;
  vertical-align: middle;
}

.LocationCardsContainer{
  position: relative;
  display: inline-block;
  width: 40%;
  margin: 0 5%;
  max-width: 400px;
  vertical-align: middle;
  margin: 24px;
  overflow: visible;
}

.LocationCardsContainer::before{
  content:'';
  position: absolute;
  top: 0;
  left: -2px;
  width: 16%;
  height: 100%;
  z-index: 9;
  background-image: -webkit-gradient(linear, left top, right top, from(#fff) , to(rgba(255,255,255,0.001)));
  background-image: linear-gradient(to right, #fff , rgba(255,255,255,0.001));
}

.LocationCardsContainer::after{
  content:'';
  position: absolute;
  top: 0;
  right: -2px;
  width: 16%;
  height: 100%;
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(255,255,255,0.001)) , to(#fff));
  background-image: linear-gradient(to right, rgba(255,255,255,0.001) , #fff);
}

.LocationCardWrapper{
  background: #fff;
  position: relative;
}

.LocationCard{
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 1px 5px 10px -2px #00000044;
  width: calc(100% - 40px);
  margin: 16px 12px;
  padding: 8px;
  text-align: center;
}

.LocationCardLogo{
  width: 32%;
  margin: auto;
  padding: 12px;
}

.LocationCardHeader{
  font-size: 18px;
  font-weight: 900;
  color: #222;
  margin: 0;
}

.LocationCardButton{
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 3px;
  background:#ccc;
  color: #fff;
  padding: 8px 24px;
  border:0;
  border-radius: 12px;
  box-shadow: 1px 5px 10px -3px #00000088;
  -webkit-transition: all .2s;
  transition: all .2s;
  margin: 12px auto;
  text-transform: uppercase;
}

.LocationCardButton:hover{
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
  box-shadow: 1px 10px 12px -3px #00000088;
  cursor: pointer;
}

.LocationCardAddress{
  font-size: 14px;
  font-weight: 700;
  padding: 8px;
  line-height: 1.2;
}

.LocationCardHours{
  font-size: 12px;
  font-weight: 600;
  color: #222;
  padding: 12px;
  line-height: 1.6;
}

.LocationCardsNext{
  position: absolute;
  top: 50%;
  right: -42px;
  width: 42px;
  -webkit-transition: all .2s;
  transition: all .2s;
  opacity: .4;
  cursor: pointer;
  z-index: 9;
  background: transparent;
}

.LocationCardsNext:hover{
  opacity: .8;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.LocationCardsPrev{
  position: absolute;
  top: 50%;
  left: -42px;
  width: 42px;
  -webkit-transition: all .2s;
  transition: all .2s;
  opacity: .4;
  cursor: pointer;
  z-index: 9;
  background: transparent;
}

.LocationCardsPrev:hover{
  opacity: .8;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.LocationList{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.LocationListHours{
  font-size: 12px;
  font-weight: 600;
  color: #222;
  padding: 4px;
  line-height: 1.6;
}

.LocationListElement{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 8px 16px;
  margin: 4px;
  border-radius: 8px;
  border: 1px solid #e1e1e1;
  width: 100%;
  vertical-align: top;
}

.LocationSelected{
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  padding: 8px 16px;
  margin: 4px;
  border-radius: 8px;
  border: 1px solid #e1e1e1;
}

.LocationButtons{
  width: 30%;
  margin: 16px 0;
}

.LocationSelectedHeader{
  font-size: 24px;
  font-weight: 900;
  color: #222;
  margin: 0;
}

.LocationSelectedLogo {
  width: 64%;
  box-sizing: border-box;
  padding: 16px;
  vertical-align: middle;
}

.LocationSelectedText{
  /* width: 33%; */
  box-sizing: border-box;
  padding: 8px 32px;
  text-align: center;
  vertical-align: middle;
}

.LocationListButton{
  width: 100%;
  padding: 12px 32px;
  box-sizing: border-box;
  color: white;
  border: 0;
  border-radius: 8px;
  margin: 2px;
  font-size: 16px;
  cursor: pointer;
}

.LocationDirections{
  font-weight: 700;
  text-decoration: none;
  margin: 2px;
}

.LocationPhone{
  font-weight: 700;
  text-decoration: none;
  margin: 2px;
}

@media only screen and (max-width: 800px){
  .LocationMap{
    width: 100%;
    height: 300px;
    margin: 12px 0px;
  }
  .LocationCardsContainer{
    width: 100%;
    margin: 0px;
  }
  .LocationCardsPrev{
    left: -24px;
  }
  .LocationCardsNext{
    right: -24px;
  }
  .LocationList{
    flex-direction: column;
  }
  .LocationSelected{
    flex-direction: column;
  }
  .LocationSelectedLogo {
    width: 48%;
  }
  
  .LocationSelectedText{
    width: 100%;
  }

  .LocationButtons{
    width: 100%;
  }
}

.restMapContainer{
  position: relative;
  display: inline-block;
  width: 100%;
  height: 400px;
  background: #ccc;
  border-radius: 16px;
  overflow: hidden;
}

.restMapAddressInput{
  position: absolute;
  top: 10px;
  left: 8px;
  width: calc(100% - 112px);
  padding: 12px 16px;
  font-size: 14px;
  color: #333;
  border: 0;
  border-radius: 4px;
  background: #fff;
  box-shadow: 1px 5px 20px -1px #00000033;
  z-index: 9;
}

.restMapAutocompleteContainer{
  position: absolute;
  top: 56px;
  left: 8px;
  width: calc(100% - 48px);
  padding: 12px 16px;
  font-size: 14px;
  color: #333;
  border: 0;
  border-radius: 4px;
  background: #fff;
  box-shadow: 1px 10px 10px -1px #00000033;
  z-index: 9;
}

.restMapAutocompleteContainer:empty{
  height: 0;
  padding: 0;
}

.restMapImg{
  position: relative;
  min-width: 100%;
  object-fit: cover;
}

.restMapAddressSubmit{
  position: absolute;
  top: 17px;
  right: 16px;
  height: 24px;
  width: 24px;
  padding: 4px;
  background: #4284f4;
  border: 0;
  border-radius: 4px;
  z-index: 901;
  cursor: pointer;
}
.MenuContainer{
  position: relative;
  width: calc(100% - 48px);
  /* min-height: calc(100vh - 48px); */
  padding: 24px;
}

.MenuCategoryContainer{
  position: relative;
  display: inline-block;
  width: 27%;
  margin: 24px;
  vertical-align: top;
}

.MenuCategory{
  font-size: 24px;
  font-weight: 700;
  margin: 4px 0px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.MenuItem{
  position: relative;
  font-size: 16px;
  font-weight: 300;
  margin: 2px 4px;
  width: calc(100% - 72px);
  padding-right: 72px;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
}

.MenuItemPrice{
  font-weight: 600;
  position: absolute;
  top: 0;
  right: 0;
}

@media only screen and (max-width: 800px){
  .MenuCategoryContainer{
    width: calc(100% - 48px);
  }
}
.ReviewsContainer{
  position: relative;
  width: calc(100% - 48px);
  padding: 24px;
}

.ReviewsCardContainer{
  position: relative;
  display: inline-block;
  width: 80%;
  margin: 64px 10%;
  vertical-align: middle;
  overflow: visible;
}

.ReviewsCardContainer::before{
  content:'';
  position: absolute;
  top: 0;
  left: -2px;
  width: 16%;
  height: 100%;
  z-index: 9;
  background-image: -webkit-gradient(linear, left top, right top, from(#fff) , to(rgba(255,255,255,0.001)));
  background-image: linear-gradient(to right, #fff , rgba(255,255,255,0.001));
}

.ReviewsCardContainer::after{
  content:'';
  position: absolute;
  top: 0;
  right: -2px;
  width: 16%;
  height: 100%;
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(255,255,255,0.001)) , to(#fff));
  background-image: linear-gradient(to right, rgba(255,255,255,0.001) , #fff);
}

.ReviewWrapper{
  background: #fff;
  position: relative;
}

.ReviewCard{
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 1px 5px 10px -2px #00000044;
  margin: 16px 12px;
  padding: 24px 8px;
  text-align: center;
}

.ReviewCardLogo{
  width: 48%;
  margin: auto;
  padding: 12px;
}

.ReviewCardHeader{
  font-size: 18px;
  font-weight: 900;
  color: #222;
}

.ReviewCardButton{
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1px;
  color: #fff;
  padding: 8px 24px;
  border:0;
  border-radius: 12px;
  box-shadow: 1px 5px 10px -3px #00000088;
  -webkit-transition: all .2s;
  transition: all .2s;
  margin: 4px auto;
  text-transform: uppercase;
}

.ReviewCardButton:hover{
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
  box-shadow: 1px 10px 12px -3px #00000088;
  cursor: pointer;
}

.ReviewsCardHours{
  font-size: 14px;
  font-weight: 200;
  color: #222;
  padding: 12px;
}

.ReviewsCardsNext{
  position: absolute;
  top: 50%;
  right: -42px;
  width: 42px;
  -webkit-transition: all .2s;
  transition: all .2s;
  opacity: .4;
  cursor: pointer;
}

.ReviewsCardsNext:hover{
  opacity: .8;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.ReviewsCardsPrev{
  position: absolute;
  top: 50%;
  left: -42px;
  width: 42px;
  -webkit-transition: all .2s;
  transition: all .2s;
  opacity: .4;
  cursor: pointer;
}

.ReviewsCardsPrev:hover{
  opacity: .8;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.ReviewCardBody{
  font-size: 12px;
  font-weight: 600;
  color: #aaa;
  padding: 8px 0px 24px 0;
  font-weight: 500;
  color: #888;
  position: relative;
  position: relative;
  height: 12em;
  overflow-y: auto;
}

@media only screen and (max-width: 800px){
  .ReviewsCardContainer{
    width: 100%;
    padding: 0;
    margin: 0;
  }
  .ReviewCardButton{
    padding: 8px 0;
    width: 100%;
  }
  /* .ReviewCardBody{
    font-size: 12px;
    font-weight: 500;
    color: #666;
    padding: 8px 0px;
  } */
  .ReviewsCardsNext{
    position: absolute;
    top: 50%;
    right: -16px;
    width: 32px;
    z-index: 11;
    -webkit-transition: all .2s;
    transition: all .2s;
    opacity: .4;
    cursor: pointer;
  }
  
  .ReviewsCardsPrev{
    position: absolute;
    top: 50%;
    left: -16px;
    width: 32px;
    z-index: 11;
    -webkit-transition: all .2s;
    transition: all .2s;
    opacity: .4;
    cursor: pointer;
  }
}
.ContactContainer{
  position: relative;
  width: calc(100% - 48px);
  padding: 96px 24px;
}

.ContactImg{
  position: relative;
  display: inline-block;
  width: 20%;
  margin: 0 15%;
  height: 60%;
  max-width: 600px;
  vertical-align: middle;
  object-fit: cover;
}

.ContactBody{
  position: relative;
  display: inline-block;
  width: 40%;
  margin: 0 5%;
  height: 60%;
  max-width: 600px;
  vertical-align: middle;
}

.ContactLabel{
  font-size: 12px;
  font-weight: 200;
  color: #222;
  padding: 4px 0px;
  display: block;
}

.ContactInput{
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  padding: 8px 16px;
  margin: 4px;
  font-size: 16px;
  font-weight: 300;
  color: #222;
  display: block;
  width: calc(100% - 40px);
  font-family: 'Nunito Sans', sans-serif;
  font-display: swap;
}

.ContactButton{
  padding: 12px 36px;
  margin: 12px 4px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  color: white;
  background: #ccc;
  border:0;
  border-radius: 8px;
  -webkit-transition: all .2s;
  transition: all .2s;
  box-shadow: 1px 5px 10px -1px #00000066;
}

.ContactButton:hover{
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
  box-shadow: 1px 8px 15px -2px #00000066;

}



@media only screen and (max-width: 800px){
  .ContactImg{
    width: 92%;
    padding: 4%;
    margin: 0;
  }
  .ContactBody{
    width: 92%;
    padding: 4%;
    margin: 0;
  }
}

.Background{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  overflow: hidden;
  animation: fade-in .5s forwards ease;
  -webkit-animation: fade-in .5s forwards ease;
  background: rgba(0,0,0,0.5);
}

.Modal{
  position: relative;
  width: 96%;
  box-sizing: border-box;
  height: auto;
  min-height: 100px;
  max-height: 92%;
  max-width: 600px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 2px 8px 48px -16px rgba(0,0,0,.4);
  z-index: 11;
  animation: slide-up .5s forwards ease;
  -webkit-animation: slide-up .5s forwards ease;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  overflow-y: auto;
}

.Close{
  position: absolute;
  top: 12px;
  right: 12px;
  height: 20px;
  opacity: .8;
  cursor: pointer;
  z-index: 12;
  border-radius: 50%;
  background: #fff;
  padding: 4px;
}

#ModalExit{
  animation: drop-out .5s forwards ease;
  -webkit-animation: drop-out .5s forwards ease;
}

#BackgroundExit{
  animation: fade-out .5s forwards ease;
  -webkit-animation: fade-out .5s forwards ease;
}

@keyframes slide-up {
  0%{
    -webkit-transform: translateY(100vh);
            transform: translateY(100vh);
  }
  60%{
    -webkit-transform: translateY(-6vh);
            transform: translateY(-6vh);
  }
  100%{
    -webkit-transform: translateY(0vh);
            transform: translateY(0vh);
  }
}

@-webkit-keyframes slide-up {
  0%{
    -webkit-transform: translateY(100vh);
            transform: translateY(100vh);
  }
  60%{
    -webkit-transform: translateY(-6vh);
            transform: translateY(-6vh);
  }
  100%{
    -webkit-transform: translateY(0vh);
            transform: translateY(0vh);
  }
}

@-webkit-keyframes drop-out {
  0%{
    -webkit-transform: translateY(0vh);
            transform: translateY(0vh);
  }
  40%{
    -webkit-transform: translateY(-6vh);
            transform: translateY(-6vh);
  }
  100%{
    -webkit-transform: translateY(100vh);
            transform: translateY(100vh);
  }
}

@keyframes drop-out {
  0%{
    -webkit-transform: translateY(0vh);
            transform: translateY(0vh);
  }
  40%{
    -webkit-transform: translateY(-6vh);
            transform: translateY(-6vh);
  }
  100%{
    -webkit-transform: translateY(100vh);
            transform: translateY(100vh);
  }
}



@-webkit-keyframes fade-out {
  0%{
    background: rgba(0,0,0,.5);
  }
  100%{
    background: rgba(0,0,0,0);
  }
}



@keyframes fade-out {
  0%{
    background: rgba(0,0,0,.5);
  }
  100%{
    background: rgba(0,0,0,0);
  }
}

@-webkit-keyframes fade-in {
  0%{
    background: rgba(0,0,0,0);
  }
  100%{
    background: rgba(0,0,0,0.5);
  }
}

@keyframes fade-in {
  0%{
    background: rgba(0,0,0,0);
  }
  100%{
    background: rgba(0,0,0,0.5);
  }
}
.slick-initialized .slick-track {
  display: flex;
  align-items: center;
}

.GalleryContainer{
  position: relative;
  width: calc(100% - 48px);
  padding: 24px;
}

.GalleryImg{
  /* height: 200px; */
  width: 90%;
  object-fit: cover;
  vertical-align: middle;
  cursor: pointer;
}

.GalleryContainer div {
  vertical-align: middle;
}

.GalleryCardContainer{
  position: relative;
  display: inline-block;
  width: 80%;
  margin: 64px 10%;
  vertical-align: middle;
  overflow: visible;
}

.GalleryCardContainer::before{
  content:'';
  position: absolute;
  top: 0;
  left: -2px;
  width: 16%;
  height: 100%;
  z-index: 9;
  background-image: -webkit-gradient(linear, left top, right top, from(#fff) , to(rgba(255,255,255,0.001)));
  background-image: linear-gradient(to right, #fff , rgba(255,255,255,0.001));
}

.GalleryCardContainer::after{
  content:'';
  position: absolute;
  top: 0;
  right: -2px;
  width: 16%;
  height: 100%;
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(255,255,255,0.001)) , to(#fff));
  background-image: linear-gradient(to right, rgba(255,255,255,0.001) , #fff);
}

.ReviewWrapper{
  background: #fff;
  position: relative;
}

.ReviewCard{
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 1px 5px 10px -2px #00000044;
  margin: 16px 12px;
  padding: 24px;
  text-align: center;
}

.GalleryCardsNext{
  position: absolute;
  top: 50%;
  right: -42px;
  width: 42px;
  -webkit-transition: all .2s;
  transition: all .2s;
  opacity: .4;
  cursor: pointer;
}

.GalleryCardsNext:hover{
  opacity: .8;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.GalleryCardsPrev{
  position: absolute;
  top: 50%;
  left: -42px;
  width: 42px;
  -webkit-transition: all .2s;
  transition: all .2s;
  opacity: .4;
  cursor: pointer;
}

.GalleryCardsPrev:hover{
  opacity: .8;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

@media only screen and (max-width: 800px){
  .GalleryCardContainer{
    width: 100%;
    margin: 64px 0px;
  }
  .GalleryCardsNext{
    position: absolute;
    top: 50%;
    right: -16px;
    width: 32px;
    z-index: 11;
    -webkit-transition: all .2s;
    transition: all .2s;
    opacity: .4;
    cursor: pointer;
  }
  
  .GalleryCardsPrev{
    position: absolute;
    top: 50%;
    left: -16px;
    width: 32px;
    z-index: 11;
    -webkit-transition: all .2s;
    transition: all .2s;
    opacity: .4;
    cursor: pointer;
  }
}
.HeaderContainer{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background: rgba(0,0,0,.5);
  color: #fff;
  z-index: 10;
}

.HeaderButton{
  position: relative;
  display: inline-block;
  border: 0;
  border-radius: 8px;
  padding: 8px 12px;
  margin-right: 8px;
  color: white;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: .5px;
  text-transform: uppercase;
  -webkit-transition: all .2s;
  transition: all .2s;
  cursor: pointer;
}

.HeaderButton:hover{
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
  box-shadow: 1px 5px 10px -1px #00000066;
}

.HeaderImg{
  height: 48px;
  padding: 6px;
  -webkit-transition: all .2s;
  transition: all .2s;
  margin-right: 16px;
}

.HeaderImg:hover{
  height: 56px;
  padding: 2px;
  cursor: pointer;
}

.HeaderSocialImg{
  height: 24px;
  padding: 18px 8px;
  -webkit-transition: all .2s;
  transition: all .2s;
  opacity: .5;
}

.HeaderSocialImg:hover{
  opacity: 1;
  cursor: pointer;
}

.HeaderLinks{
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  text-align: right;
}

.HeaderLinksMobile{
  position: fixed;
  top: 60px;
  right: 0px;
  width: 100%;
  text-align: right;
  background: rgba(0,0,0,.7);
  padding-bottom: 8px;
}

.HeaderLink{
  position: relative;
  display: inline-block;
  padding: 18px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: .5px;
  text-transform: uppercase;
  -webkit-transition: all .2s;
  transition: all .2s;
  cursor: pointer;
}

.HeaderLink:hover{
  font-weight: 800;
  cursor: pointer;
}

.HeaderLinkMobile{
  position: relative;
  display: block;
  padding: 8px;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: .5px;
  text-transform: uppercase;
  -webkit-transition: all .2s;
  transition: all .2s;
  cursor: pointer;
}

.HeaderMenu{
  position: absolute;
  top: 0;
  right: 0;
  height: 36px;
  padding: 8px;
}
.loadingPage{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lds-roller {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  }
  .lds-roller div {
    -webkit-animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    -webkit-transform-origin: 32px 32px;
            transform-origin: 32px 32px;
  }
  .lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #4284f4;
    margin: -3px 0 0 -3px;
  }
  .lds-roller div:nth-child(1) {
    -webkit-animation-delay: -0.036s;
            animation-delay: -0.036s;
  }
  .lds-roller div:nth-child(1):after {
    top: 50px;
    left: 50px;
  }
  .lds-roller div:nth-child(2) {
    -webkit-animation-delay: -0.072s;
            animation-delay: -0.072s;
  }
  .lds-roller div:nth-child(2):after {
    top: 54px;
    left: 45px;
  }
  .lds-roller div:nth-child(3) {
    -webkit-animation-delay: -0.108s;
            animation-delay: -0.108s;
  }
  .lds-roller div:nth-child(3):after {
    top: 57px;
    left: 39px;
  }
  .lds-roller div:nth-child(4) {
    -webkit-animation-delay: -0.144s;
            animation-delay: -0.144s;
  }
  .lds-roller div:nth-child(4):after {
    top: 58px;
    left: 32px;
  }
  .lds-roller div:nth-child(5) {
    -webkit-animation-delay: -0.18s;
            animation-delay: -0.18s;
  }
  .lds-roller div:nth-child(5):after {
    top: 57px;
    left: 25px;
  }
  .lds-roller div:nth-child(6) {
    -webkit-animation-delay: -0.216s;
            animation-delay: -0.216s;
  }
  .lds-roller div:nth-child(6):after {
    top: 54px;
    left: 19px;
  }
  .lds-roller div:nth-child(7) {
    -webkit-animation-delay: -0.252s;
            animation-delay: -0.252s;
  }
  .lds-roller div:nth-child(7):after {
    top: 50px;
    left: 14px;
  }
  .lds-roller div:nth-child(8) {
    -webkit-animation-delay: -0.288s;
            animation-delay: -0.288s;
  }
  .lds-roller div:nth-child(8):after {
    top: 45px;
    left: 10px;
  }
  @-webkit-keyframes lds-roller {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @keyframes lds-roller {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
.AnnouncementBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0,.5);
  z-index: 998;
}

.AnnouncementContainer{
  position: fixed;
  width: calc(100% - 32px);
  min-height: 100px;
  max-height: 90%;
  max-width: 600px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 2px 8px 48px -16px rgba(0,0,0,.4);
  z-index: 11;
  animation: slide-up .5s forwards ease;
  -webkit-animation: slide-up .5s forwards ease;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
}

.AnnouncementClose{
  position: absolute;
  top: 12px;
  right: 12px;
  height: 20px;
  opacity: .8;
  cursor: pointer;
  z-index: 12;
  border-radius: 50%;
  background: #fff;
  padding: 4px;
}

.AnnouncementHeader{
  font-size: 24px;
  font-weight: 900;
  color: #222;
  margin: 0px 24px;
}

.AnnouncementImg{
  width: 32%;
  margin: 12px auto;
}

.AnnouncementBody{
  font-size: 18px;
  font-weight: 400;
  color: #222;
  /* margin: 8px 16px; */
}

@-webkit-keyframes drop-in {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    opacity: 0;
    margin-top: -2.5vh;
  }
  30% {
    opacity: 0;
    -webkit-transform: translateY(-20%);
            transform: translateY(-20%);
    margin-top: 0vh;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
    margin-top: 2.5vh;
  }
}

@keyframes drop-in {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    opacity: 0;
    margin-top: -2.5vh;
  }
  30% {
    opacity: 0;
    -webkit-transform: translateY(-20%);
            transform: translateY(-20%);
    margin-top: 0vh;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
    margin-top: 2.5vh;
  }
}
.CustomSectionContainer{
  position: relative;
  width: calc(100% - 48px);
  /* min-height: calc(100vh - 48px); */
  padding: 64px 24px;
}

.CustomSectionImg{
  position: relative;
  display: inline-block;
  width: 40%;
  margin: 0 5%;
  height: 60%;
  max-width: 600px;
  vertical-align: middle;
  object-fit: cover;
}

.CustomSectionBody{
  position: relative;
  display: inline-block;
  width: 40%;
  margin: 0 5%;
  height: 60%;
  max-width: 600px;
  vertical-align: middle;
}

@media only screen and (max-width: 800px){
  .CustomSectionImg{
    width: 92%;
    padding: 4%;
    margin: 0;
  }
  .CustomSectionBody{
    width: 92%;
    padding: 4%;
    margin: 0;
  }
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-family: "Nunito Sans", sans-serif;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  font-display: swap;
}

#siteVersion {
  padding: 16px;
  font-size: 12px;
  color: #ccc;
  text-align: center;
}

.PageSlider{
  position: relative;
  width: 92%;
  max-width: 1000px;
  margin: 24px auto 64px auto;
  background: rgba(255,255,255,1);
  border-radius: 24px;
  box-shadow: 1px 5px 50px -5px rgba(0,0,0,1);
}

.Disclosures{
  position: relative;
  display: block;
  width: 100%;
  padding: 32px 32px 16px 32px;
  font-size: 12px;
  font-weight: 500;
  color: #666;
  text-align: center;
  box-sizing: border-box;
}

