@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:100,200,300,400,500,600,700,800,900");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-family: "Nunito Sans", sans-serif;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  font-display: swap;
}

#siteVersion {
  padding: 16px;
  font-size: 12px;
  color: #ccc;
  text-align: center;
}

.PageSlider{
  position: relative;
  width: 92%;
  max-width: 1000px;
  margin: 24px auto 64px auto;
  background: rgba(255,255,255,1);
  border-radius: 24px;
  box-shadow: 1px 5px 50px -5px rgba(0,0,0,1);
}

.Disclosures{
  position: relative;
  display: block;
  width: 100%;
  padding: 32px 32px 16px 32px;
  font-size: 12px;
  font-weight: 500;
  color: #666;
  text-align: center;
  box-sizing: border-box;
}
